<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" v-if="!taskData">
      <h4 class="alert-heading">Error fetching task data</h4>
      <div class="alert-body">
        No task found with this task id. Check
        <b-link class="alert-link" :to="{ name: 'apps-tasks-list' }"> Task List </b-link>
        for other tasks.
      </div>
    </b-alert>
    <template v-if="taskData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" v-if="taskData">
          <task-view-action :task-data="taskData" />
          <!-- <TaskViewAction :task-data="taskData" /> -->
        </b-col>
      </b-row>

      <!-- <b-col cols="12" lg="6">
          <user-view-user-permissions-card />
        </b-col> -->
      <!-- </b-row> -->
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BAlert,
} from "bootstrap-vue";

import taskModule from "../staffWelcomeStoreModule";
import TaskViewAction from "./TaskViewAction.vue";

export default {
  components: {
    BCard,
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BLink,

    // Local Components
    TaskViewAction,
  },
  setup() {
    const taskData = ref({});

    const USER_APP_STORE_MODULE_NAME = "app-staff-task";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, taskModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-staff-task/fetchStaffTask", { id: router.currentRoute.params.id })
      .then((response) => {
        console.log(router.currentRoute.params.id)
        console.log("task detail", response);
        taskData.value = response.data.task[0];
      })
      .catch((error) => {
        taskData.value = undefined;
      });

    return {
      taskData,
    };
  },
};
</script>

<style></style>
