<template>
  <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
    <b-form @submit.prevent="submitMail(taskData.id)">
      <b-row v-if="taskData">
        <b-col md="6">
          <b-form-group label="Staff Name" label-for="user_name">
            <b-form-input readonly id="user_name" v-model="taskData.user_name" placeholder="Staff Name" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Task Stage" label-for="task_stage_id">
            <!-- <v-select
            readonly
            v-model="taskData.task_stage_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="taskStageOptions"
            label="stage_name"
            :reduce="(option) => option.id"
            :clearable="false"
            placeholder=""
          /> -->
            <b-form-input v-if="taskData.task_stage" readonly id="stage_name" v-model="taskData.task_stage.stage_name"
              placeholder="Client Name" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Email" label-for="user_email">
            <b-form-input readonly id="user_email" v-model="taskData.user_email" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Company" label-for="company_name">
            <div class="form-label-group">
              <b-form-input readonly v-model="taskData.company_name" id="company_name" placeholder="Company" />
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="12" class="mb-1">
          <b-form-group label="Message" label-for="email_content">
            <quill-editor class="email-messge-editor" :style="{ height: '270px', borderRadius: '4px' }"
              :options="editorOption" v-model="taskData.email_content" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" style="margin-top: 120px" v-if="taskData.attachment_path != ''">
          <h3 class="mb-1 ml-0 color4 font-weight-bolder">Attachment</h3>
        </b-col>

        <!-- <div class="row">
        <div class="col-sm-12">
          <div class="form-group" style="height: 500px">
            <label class="form-label">Certificado Previo</label>
            <embed id="pdfcontainer" type="application/pdf" width="100%" height="100%" />
          </div>
        </div>
      </div> -->

        <b-col cols="12" md="12" class="mt-2 pb-3" v-if="taskData.attachment_path != ''">
          <!-- <pdf style="width: 350px; height: 200px; padding: 0 10px"
          src="http://192.168.50.17:8000/storage/app/uploads/sent/JAL_PIStatusExpiryReminder_3_1669019661.pdf"></pdf>
        <el-image style="width: 350px; height: 200px; padding: 0 10px"
          src="http://192.168.50.17:8000/storage/app/uploads/sent/JAL_FATCAExpirationNotice__1669017997.pdf">
        </el-image>
        <el-image style="width: 350px; height: 200px; padding: 0 10px"
          src="http://192.168.50.17:8000/storage/app/uploads/sent/JAL_FATCAExpirationNotice__1669017997.pdf">
        </el-image>
        <pdf style="width: 350px; height: 200px; padding: 0 10px"
          src="http://192.168.50.17:8000/storage/app/uploads/sent/JAL_FATCAExpirationNotice__1669017997.pdf">
        </pdf> -->
          <div v-if="taskData.attachment_path && taskData.attachment_path != ''" class="form-group"
            style="height: 800px; width: 100%">
            <embed id="pdfcontainer" type="application/pdf" width="100%" height="100%" />
          </div>
          <div v-if="taskData.attachment_path &&
            taskData.attachment_path != '' &&
            taskData.attachment_path.charAt(0) == '['
            " class="form-group" style="height: 800px; width: 100%">
            <embed id="pdfcontainersecond" type="application/pdf" width="100%" height="100%" />
          </div>
        </b-col>

        <b-col class="mt-5">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-if="ability.can('update', 'actionlist')" type="submit"
            variant="primary" class="mr-1">
            Resend Email
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.go(-1)">
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BForm,
  BFormSelect,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormFile,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BCollapse,
  BFormInvalidFeedback,
  VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import router from "@/router";

import vSelect from "vue-select";

import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";
import pdf from "vue-pdf";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { onMounted, ref } from "@vue/composition-api";
import store from "@/store";
import axiosIns from "@/libs/axios";
import ability from "@/libs/acl/ability";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardBody,
    BButton,
    BOverlay,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormSelect,
    BFormFile,
    BCollapse,
    vSelect,
    BFormInvalidFeedback,
    pdf,
    // Date Picker
    flatPickr,

    // Editor
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  props: {
    taskData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editorOption: {
        placeholder: "",
      },
      loading: false,
    };
  },
  // watch: {
  //   taskData: {
  //     immediate: true,
  //     handler(newValue, oldValue) {
  //       this.formattedEmailContent();
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    formattedEmailContent() {
      this.taskData.email_content = this.taskData.email_content
        .replace("&nbsp;  &nbsp;&nbsp;&nbsp; Dear", "Dear")
        .trim();
    },
    submitMail() {
      console.log("sending form data", this.taskData);
      this.loading = true;
      let newFormData = new FormData();

      if (this.taskData && this.taskData.id) {
        newFormData.append("document_id", this.taskData.id);
      } else {
        newFormData.append("attachment[]", this.taskData.attachment);
        // this.taskData.attachment.forEach((item) => {
        //   newFormData.append("attachment[]", item);
        // });
      }
      newFormData.append("to[]", this.taskData.user_email);
      newFormData.append("user_account_id", this.taskData.user_id);
      if (this.taskData.task_category_id == 1) {
        newFormData.append("task_category_id", this.taskData.task_category_id);
        console.log(this.taskData.attachment_path)

        // Assuming this.taskData.attachment_path is a string containing the array representation
        var attachmentPathString = `${this.taskData.attachment_path}`;

        // Replace single quotes with double quotes
        attachmentPathString = attachmentPathString.replace(/'/g, '"');

        // Parse the modified string as JSON
        var attachmentPathArray = JSON.parse(attachmentPathString);


        // let attachmentPathArray = JSON.parse(this.taskData.attachment_path);

        attachmentPathArray.forEach((item) => {
          newFormData.append("attachment[]", item);
        });
      } else if (this.taskData.task_category_id == 8) {
        newFormData.append("task_category_id", this.taskData.task_category_id);
        console.log(this.taskData.attachment_path)

        // Assuming this.taskData.attachment_path is a string containing the array representation
        var attachmentPathString = `['${this.taskData.attachment_path}']`;

        // Replace single quotes with double quotes
        attachmentPathString = attachmentPathString.replace(/'/g, '"');

        // Parse the modified string as JSON
        var attachmentPathArray = JSON.parse(attachmentPathString);


        // let attachmentPathArray = JSON.parse(this.taskData.attachment_path);

        attachmentPathArray.forEach((item) => {
          newFormData.append("attachment[]", item);
        });
      } else {
        newFormData.append("task_category_id", null);
      }
      // this.taskData.to.forEach((item) => {
      //   newFormData.append("to[]", item);
      // });
      // this.taskData.cc.forEach((item) => {
      //   newFormData.append("cc[]", item);
      // });
      // this.taskData.bcc.forEach((item) => {
      //   newFormData.append("bcc[]", item);
      // });
      // newFormData.append("message", this.taskData.message);
      newFormData.append("content", this.taskData.email_content);
      newFormData.append("subject", `Resend: ${this.taskData.list_name}`);
      // newFormData.append("to", this.taskData.to);
      // newFormData.append("cc", this.taskData.cc);
      // newFormData.append("bcc", this.taskData.bcc);
      // newFormData.append("schedule", this.taskData.schedule);
      console.log(newFormData);
      store
        .dispatch("app-staff-task/sendEmail", newFormData)
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: "Successfully resent the email.",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.$emit("refetch-data");
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
  },
  watch: {
    taskData: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        this.formattedEmailContent();

        console.log(newValue["attachment_path"], oldValue);
        console.log("updates");

        // first file
        if (newValue.attachment_path != "") {
          axiosIns
            .get(`/task/getStaffFile/?id=${router.currentRoute.params.id}`, {
              responseType: "blob",
            })
            .then((response) => {
              const filepdf = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURL = URL.createObjectURL(filepdf);
              if (fileURL) {
                document.getElementById("pdfcontainer").setAttribute("src", fileURL);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        console.log("hhh", typeof newValue.attachment_path);

        if (newValue.attachment_path != "" && newValue.attachment_path.charAt(0) == "[") {
          //second file
          axiosIns
            .get(`/task/getStaffSecondFile/?id=${router.currentRoute.params.id}`, {
              responseType: "blob",
            })
            .then((response) => {
              console.log("hi response", response);
              const filepdfsecond = new Blob([response.data], {
                type: "application/pdf",
              });
              const fileURLSecond = URL.createObjectURL(filepdfsecond);
              console.log(fileURLSecond);
              if (fileURLSecond) {
                document
                  .getElementById("pdfcontainersecond")
                  .setAttribute("src", fileURLSecond);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }

        // props.taskData.email_content = props.taskData.email_content.replace("{!! $account['client_name'] !!}", props.taskData.client_name ? props.taskData.client_name : props.taskData.user_name);
      },
      deep: true,
    },
  },
  setup(props, { emit }) {
    console.log(router.currentRoute.params);

    // console.log(props)
    // if (props.taskData && Object.keys(props.taskData).length == 0) {
    //   console.log('empty')
    // } else {
    //   console.log('not empty')
    // }

    // axiosIns
    //   .get(`/task/getStaffFile/?id=${router.currentRoute.params.id}`, {
    //     responseType: "blob",
    //   })
    //   .then((response) => {
    //     const filepdf = new Blob([response.data], {
    //       type: "application/pdf",
    //     });
    //     const fileURL = URL.createObjectURL(filepdf);
    //     if (fileURL) {
    //       document
    //         .getElementById("pdfcontainer")
    //         .setAttribute("src", fileURL);
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // if (props.taskData) {
    //   if (props.taskData.attachment_path[0] == '[') {
    //     axiosIns
    //       .get(`/task/getStaffSecondFile/?id=${router.currentRoute.params.id}`, {
    //         responseType: "blob",
    //       })
    //       .then((response) => {
    //         console.log('hi response', response);
    //         const filepdfsecond = new Blob([response.data], {
    //           type: "application/pdf",
    //         });
    //         const fileURLSecond = URL.createObjectURL(filepdfsecond);
    //         if (fileURLSecond) {
    //           document
    //             .getElementById("pdfcontainersecond")
    //             .setAttribute("src", fileURLSecond);
    //         }
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    //   }

    //   // console.log(props)
    //   props.taskData.email_content = props.taskData.email_content.replace("{!! $account['client_name'] !!}", props.taskData.client_name ? props.taskData.client_name : props.taskData.user_name);
    //   // console.log(props.taskData.email_content)
    // }
    const taskStageOptions = ref([]);

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptionList", { task_stage: true })
        .then((response) => {
          console.log(response);
          taskStageOptions.value = response.data.task_stages;
          console.log(taskStageOptions);
        })
        .catch((error) => {
          // if (error.response.status === 404) {
          taskStageOptions.value = undefined;
          console.log(error);
          // }
        });
    };

    onMounted(() => {
      fetchOptions();
      console.log(props);
    });

    // const APP_CLIENT_STORE_MODULE_NAME = "app-email";

    return {
      fetchOptions,
      taskStageOptions,
      ability,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";

.hide-dropdown {
  ul {
    display: none !important;
  }
}

.weekDays-selector {
  .vs__open-indicator {
    fill: $primary-color-4;
  }
}

.picker-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 310px;
  margin: 0 auto;

  .flatpickr-calendar {
    top: 0px;
    margin: 0px auto 1rem;
    order: 1;
  }

  .flatpickr-input {
    max-width: 310px;
    margin: 0 auto;
    order: 2;

    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: radial-gradient(#e0e0e0, rgba(224, 225, 226, 0.12));
      margin-top: 1rem;
    }
  }

  .invalid-feedback {
    order: 2;
  }
}

.email-messge-editor {
  .ql-snow .ql-editor {
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      padding: 2rem 0;
    }

    p {
      padding-bottom: 0.5rem;
    }
  }
}

.choose-wrapper {
  margin: 0 auto;
  max-width: 310px;

  .input-group {
    padding-top: 20px;

    label {
      margin: 0 0.3rem !important;
      color: #01573e;
    }

    &>.form-control.input-days {
      flex: 0 auto;
      width: 50px;
      margin: 0 10px;
      border-radius: 6px !important;
    }

    .weekDate-selector {
      width: 136px;
      margin-right: 0px !important;
    }
  }
}

#email .col-form-label {
  min-width: 80px;
}
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.card-header {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
}

label {
  font-size: 18px !important;
}

.btn-trigger-input-file {
  margin: 0 20px;
}

// quill
.ql-toolbar.ql-snow {
  border: 0px;
}

.btn-send {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  border-radius: 6px 0px 0px 6px;

  &.full {
    border-radius: 4px;
  }
}

.btn-drop-down-schedule {
  border-radius: 0 6px 6px 0px;
  padding-right: 1rem;
  padding-left: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.btn-schedule-open {
  position: absolute;
  top: -65px;
  left: -36px;
  padding: 1rem 1.5rem;
  white-space: nowrap;
  border-radius: 6px;
  background: #fff;
  border: 0.5px solid #e0e0e0;
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1);
}

.file-wrapper {
  position: relative;
  display: inline-block;
  width: calc(100% - 10.25rem);
  color: #013e79;

  .file-list {
    display: inline-block;
    width: 77%;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    text-overflow: ellipsis;
  }

  img {
    display: inline;
    max-width: 150px;
  }

  .error-msg {
    padding-left: 10px;
  }
}

.btn-delete-file {
  position: absolute;
  right: 20px;
  top: 0px;
}

.other-input-list {
  position: absolute;
  top: 10px;
  right: -50px;
}

@media screen and (max-width: 768px) {
  .file-wrapper {
    width: 100%;
    margin-top: 1rem;

    .btn-trigger-input-file {
      margin: 0 10px;
    }
  }
}

@media screen and (max-width: 575px) {
  .file-wrapper .file-list {
    width: 70%;
  }
}
</style>
